import React from "react";
import { css } from "@emotion/core";
import { colorDark, colorLight, bpLarge } from "../../stylingConstants";
import { verticalMargin } from "../../margins";
import { ExternalLink } from "../Atoms/Icons";
import { useGlobalSiteData } from "../../hooks";
import MaybeExternalLink from "../Atoms/MaybeExternalLink";
import { displayTypeface } from "../Atoms/Text";

const listCss = (theme: "onDark" | "onLight") => css`
  color: ${theme === "onDark" ? "white" : colorDark};
  list-style: none;
  padding: 0;
  ${verticalMargin(36)}
`;

const listItemCss = css`
  display: block;
  font-family: ${displayTypeface};
  font-size: 24px;
  ${verticalMargin(20)};
  @media ${bpLarge} {
    font-size: 36px;
    ${verticalMargin(32)}
  }
`;

export default function SiteNavigation({
  theme,
  ...props
}: {
  theme: "onDark" | "onLight";
}) {
  const { menuItems } = useGlobalSiteData();
  return (
    <nav>
      <ul css={listCss(theme)} {...props}>
        {menuItems.map(({ text, url }) => {
          if (url == null) {
            return null;
          }

          return (
            <li css={listItemCss} key={`${text}${url}`}>
              <MaybeExternalLink
                to={url}
                externalTarget="_blank"
                externalSuffix={
                  <ExternalLink
                    fill={theme === "onLight" ? colorDark : undefined}
                    css={css`
                      margin-left: 8px;
                      vertical-align: middle;
                    `}
                  />
                }
              >
                {text}
              </MaybeExternalLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
