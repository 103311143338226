import { GatsbyLinkProps, Link } from "gatsby";
import React, { ReactNode, ComponentProps } from "react";

export default function MaybeExternalLink<TState>({
  children,
  externalSuffix,
  externalTarget,
  ...props
}: GatsbyLinkProps<TState> & {
  externalSuffix?: ReactNode;
  externalTarget?: string;
}) {
  const [isExternal, LinkComponent] =
    props.to.startsWith("http") ||
    props.to.startsWith("mailto") ||
    props.to.startsWith("tel")
      ? [
          true,
          (props: GatsbyLinkProps<{}>) => (
            <a target={externalTarget} {...props} href={props.to} />
          ),
        ]
      : [false, Link];
  return (
    <LinkComponent {...props}>
      {children}
      {isExternal && externalSuffix}
    </LinkComponent>
  );
}
