import { css } from "@emotion/core";
import React, { useEffect } from "react";
import { useDelayedFollower, useEffectWithDeps } from "../../hooks";
import { useLocation } from "@reach/router";

type Props = {
  show: boolean;
  onClose(): void;
  children: (overLayTransition: boolean) => React.ReactNode;
};

export const backdropFadeDuration = 300;

type EventListenerParams = Parameters<HTMLElement["addEventListener"]>;
export default function Overlay({ show, onClose, children }: Props) {
  const shouldRender = useDelayedFollower(show, 0, [false, 250]);
  useEffect(() => {
    if (!shouldRender) {
      return;
    }
    const events = [
      [
        "mousewheel",
        (e: Event) => e.preventDefault(),
        { passive: false },
      ] as EventListenerParams,
      [
        "keyup",
        (e: KeyboardEvent) => {
          e.key.toLowerCase() == "escape" && onClose();
        },
      ] as EventListenerParams,
    ];
    events.forEach((event) => document.body.addEventListener(...event));
    return () => {
      events.forEach((event) => document.body.removeEventListener(...event));
    };
  }, [shouldRender]);

  const location = useLocation();
  useEffectWithDeps(
    (deps) => {
      if (deps?.[0] != null) {
        onClose();
      }
    },
    [location]
  );

  const transitioning = !(shouldRender && show);
  if (!shouldRender && !show) {
    return null;
  }

  return (
    <div
      css={css`
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        z-index: 2;
        height: 100vh;
        width: 100vw;
      `}
    >
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: black;
          opacity: ${transitioning ? 0 : 0.3};
          transition: opacity 250ms;
        `}
        onClick={onClose}
      ></div>
      {children(transitioning)}
    </div>
  );
}
