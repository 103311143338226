import { PropsWithChildren } from "react";
import React from "react";
import Overlay from "./Overlay";
import { css } from "@emotion/core";

export default function Modal(
  props: PropsWithChildren<{ show: boolean; onClose(): void }>
) {
  return (
    <Overlay {...props}>
      {(transitioning) => (
        <div
          css={css`
            background: white;
            padding: 36px;
            opacity: ${transitioning ? 0 : 1};
            transition: opacity 150ms, transform 200ms ease-in-out;
            transform: translateY(${transitioning ? 10 : 0}%);
          `}
        >
          {props.children}
        </div>
      )}
    </Overlay>
  );
}
