import { css } from "@emotion/core";
import "normalize.css";
import React, {
  ComponentProps,
  PropsWithChildren,
  useMemo,
  useState,
} from "react";
import "../../assets/fonts/fonts.css";
import GlobalStyle from "../../GlobalStyle";
import { useDelayedFollower } from "../../hooks";
import BackLink from "../Atoms/BackLink";
import { ArchiveLayout } from "./ArchiveLayout";
import Footer from "./Footer";
import Header from "./Header";
import MainContent from "./Main";
import YearSelectMenu from "./YearSelectMenu";
import CookieBanner from "../Atoms/CookieBanner";
import { CategoryOrRolePickerWithButton } from "./CategoryPicker";

const fadeOutDuration = 100;

type ContextProps = {
  location: Location;
  pageContext: {
    noWrapper?: boolean;
    year?: string;
    personSlug?: string;
    categoryName?: string;
    categories?: [string, string][];
    useArchiveLayout?: boolean;
  };
};

export default function Layout({
  children,
  pageContext,
  location,
}: PropsWithChildren<ContextProps>) {
  const {
    renderChildren,
    selectedArchiveYear,
    personSlug,
    noWrapper,
    categoryName,
    useArchiveLayout,
  } = useDelayedFollower(
    useMemo(
      () => ({
        renderChildren: children,
        selectedArchiveYear: pageContext.year,
        personSlug: pageContext.personSlug,
        noWrapper: pageContext.noWrapper,
        categoryName: pageContext.categoryName,
        useArchiveLayout: pageContext.useArchiveLayout,
      }),
      [
        pageContext.year,
        pageContext.personSlug,
        pageContext.noWrapper,
        pageContext.categoryName,
        pageContext.useArchiveLayout,
        children,
      ]
    ),
    fadeOutDuration
  );
  const visible = renderChildren === children;
  const opacity = visible ? 1 : 0;
  const transitionStyle = {
    opacity,
    transition: `opacity ${
      visible ? "250ms ease-out 50ms" : `${fadeOutDuration}ms`
    }`,
    height: !visible ? 100000 : undefined,
  };

  const Wrapper = noWrapper
    ? (props: ComponentProps<"div">) => <div {...props} />
    : MainContent;

  return (
    <>
      <GlobalStyle />
      <CookieBanner />
      <div
        css={css`
          position: relative;
        `}
      >
        <Header showEmbed={location.pathname === "/"} />
        <div
          css={css`
            background: white;
            padding: 0.1px;
          `}
        >
          {selectedArchiveYear || categoryName || useArchiveLayout ? (
            <ArchiveLayout
              key="archive"
              mainContentStyle={transitionStyle}
              leftColumn={
                <>
                  {pageContext.categories && (
                    <CategoryOrRolePickerWithButton
                      buttonProps={{ css: { marginBottom: 16 } }}
                      categories={pageContext.categories}
                    />
                  )}
                  <YearSelectMenu selectedOption={selectedArchiveYear} />
                </>
              }
            >
              {renderChildren}
            </ArchiveLayout>
          ) : personSlug ? (
            <ArchiveLayout
              mainContentStyle={transitionStyle}
              leftColumn={<BackLink />}
            >
              {children}
            </ArchiveLayout>
          ) : (
            <Wrapper id="mainContent" key="standard" style={transitionStyle}>
              {renderChildren}
            </Wrapper>
          )}
        </div>
        <Footer css={transitionStyle} />
      </div>
    </>
  );
}
