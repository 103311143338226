import {
  BLOCKS,
  INLINES,
  Document as RichTextDocument,
  Inline as RichTextInline,
  Block as RichTextBlock,
  Text as RichTextText,
} from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { Heading3, Heading5 } from "../Atoms/Text";
import { css } from "@emotion/core";
import React from "react";
import { bpMedium, bpLarge, colorLight } from "../../stylingConstants";
import Blockquote from "../Atoms/Blockquote";
import BulletList, { BulletListItem } from "../Atoms/BulletList";
import { MainCategoryHeading } from "../Atoms/CategoryHeading";
import TextLink from "../Atoms/TextLink";
import { verticalMargin, horizontalMargin } from "../../margins";
import VideoEmbed from "../Atoms/VideoEmbed";
import { contentSmallWidth, contentLargeWidth } from "./ContentPageLayout";

type RichTextNode = RichTextInline | RichTextBlock | RichTextText;

const inlineAndTextTypes = [
  "text",
  INLINES.ASSET_HYPERLINK,
  INLINES.EMBEDDED_ENTRY,
  INLINES.ENTRY_HYPERLINK,
  INLINES.HYPERLINK,
];

const nodeIsInline = (
  node: RichTextNode
): node is RichTextText | RichTextInline => {
  return inlineAndTextTypes.indexOf(node.nodeType) != -1;
};

const extractInlineChildren = (
  node: RichTextNode,
  acc: (RichTextText | RichTextInline)[] = []
): (RichTextText | RichTextInline)[] => {
  if (nodeIsInline(node)) {
    acc.push(node);
    return acc;
  }
  (node.content as RichTextNode[]).forEach((node) =>
    extractInlineChildren(node, acc)
  );
  return acc;
};

//Not sure why i needed to code this myself.
const renderInlineOnly = (node: RichTextNode) =>
  documentToReactComponents({
    nodeType: BLOCKS.DOCUMENT,
    //@ts-ignore, Sending Textblocks here is fine.
    content: extractInlineChildren(node),
    data: {},
  });

const options: Options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => (
      <MainCategoryHeading>{children}</MainCategoryHeading>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <p
        css={css`
          white-space: pre-wrap;
        `}
      >
        {children}
      </p>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Heading3
        css={css`
          margin-top: 38px;
          margin-bottom: 28px;
          @media ${bpMedium} {
            margin-top: 64px;
          }
          @media ${bpLarge} {
            margin-top: 100px;
            margin-bottom: 52px;
          }
        `}
      >
        {children}
      </Heading3>
    ),
    [BLOCKS.HEADING_5]: (_node, children) => (
      <Heading5
        css={css`
          color: ${colorLight};
          margin-bottom: -8px;
          margin-top: 18px;
          @media ${bpLarge} {
            margin-top: 22px;
          }
        `}
      >
        {children}
      </Heading5>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => <BulletList>{children}</BulletList>,
    [BLOCKS.LIST_ITEM]: (node) => (
      <BulletListItem>{renderInlineOnly(node)}</BulletListItem>
    ),
    [BLOCKS.QUOTE]: (node) => (
      <Blockquote
        css={css`
          ${verticalMargin(20)}
        `}
      >
        {renderInlineOnly(node)}
      </Blockquote>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const slugValue: string | undefined = node.data?.target?.fields?.slug;
      if (slugValue == null) {
        return;
      }
      return (
        <TextLink
          to={
            slugValue.startsWith("mailto") || slugValue.startsWith("tel")
              ? slugValue
              : `/${slugValue}`
          }
        >
          {children}
        </TextLink>
      );
    },
    [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
      return <TextLink to={uri}>{children}</TextLink>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      const contentType = node.data.target?.sys?.contentType?.sys?.id;
      switch (contentType) {
        case "embeddedVideo": {
          const { url, thumbnail } = node.data.target.fields as {
            url: string;
            thumbnail?: { fields?: { file?: { url?: string } } };
          };
          return (
            <EmbeddedVideoEmbed
              url={url}
              thumbnail={thumbnail?.fields?.file?.url}
            />
          );
        }
        case "imageGroup": {
          const { images, layout } = node.data.target.fields as {
            layout?: "From left" | "From right";
            images?: { fields?: { file?: { url?: string } } }[];
          };
          return (
            <div
              css={css`
                @media ${bpMedium} {
                  height: ${(images?.length ?? 0) * 255}px;
                }
              `}
            >
              {images
                ?.map((node) => node.fields?.file?.url)
                .filter((url) => url)
                .map((url, index) => {
                  const toTheLeft =
                    (index + (layout === "From left" ? 1 : 0)) % 2 > 0
                      ? true
                      : false;
                  return (
                    <>
                      <figure
                        css={css`
                        width: 100%;
                        ${horizontalMargin(0)}
                        ${verticalMargin(24)}
                        @media ${bpMedium} {
                          ${verticalMargin(64)}
                          position: absolute;
                          max-width: 48%;
                          ${{ [toTheLeft ? "left" : "right"]: 0 }}
                          height: 300px;
                          display: flex;
                          flex-direction: row;
                          display: flex;
                          justify-content: ${
                            toTheLeft ? "flex-end" : "flex-start"
                          };
                          align-items: center;
                        }
                      `}
                      >
                        <img
                          src={`${url}?h=300`}
                          css={css`
                            object-fit: contain;
                            max-height: 100%;
                            max-width: 100%;
                          `}
                        />
                      </figure>
                      <div
                        css={css`
                          display: none;
                          @media ${bpMedium} {
                            display: block;
                            height: 100px;
                          }
                        `}
                      />
                    </>
                  );
                })}
            </div>
          );
        }
        default: {
          return null;
        }
      }
    },
  },
};

const EmbeddedVideoEmbed = ({
  url,
  thumbnail,
}: {
  url: string;
  thumbnail?: string;
}) => (
  <VideoEmbed
    src={url}
    thumbSrc={thumbnail}
    css={css`
  width: 100%;
  height: ${(contentSmallWidth / 16) * 9}px;
  ${verticalMargin(12)}
  @media ${bpMedium} {
    ${verticalMargin(18)}
    height: ${(contentLargeWidth / 16) * 9}px;
  }
  @media ${bpLarge}{
    ${verticalMargin(24)}
  }
`}
  />
);

export default function ContentPageRichText({
  json,
}: {
  json: RichTextDocument;
}) {
  return <>{documentToReactComponents(json, options)}</>;
}
