import { css } from "@emotion/core";
import React from "react";
import { bpLarge } from "../../stylingConstants";
import { CrossmarkIcon } from "../Atoms/Icons";
import Overlay from "./Overlay";

type Props = {
  show: boolean;
  onClose(): void;
  children: React.ReactNode;
};

export const backdropFadeDuration = 300;

export default function SideMenu({ children, ...props }: Props) {
  return (
    <Overlay {...props}>
      {(transitioning) => (
        <aside
          css={css`
            z-index: 3;
            max-width: 90%;
            background-color: white;
            position: fixed;
            right: 0px;
            top: 0px;
            height: 100%;
            opacity: ${transitioning ? 0 : 1};
            transform: translateX(${transitioning ? "300px" : "0px"});
            transition: transform 250ms, opacity 300ms;
            transition-timing-function: ease-out;
            box-sizing: border-box;
            padding: 24px;
            width: 300px;
            @media ${bpLarge} {
              width: 450px;
              padding: 40px;
              transform: translateX(${transitioning ? "450px" : "0px"});
            }
          `}
        >
          <a onClick={props.onClose} css={{ float: "right", display: "block" }}>
            <CrossmarkIcon
              css={css`
                height: 12px;
                width: 12px;
                @media ${bpLarge} {
                  width: 16px;
                  height: 16px;
                }
              `}
            />
          </a>
          <section
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            `}
          >
            {children}
          </section>
        </aside>
      )}
    </Overlay>
  );
}
