//Polyfill fetch for IE
require("unfetch/polyfill");

/** @type number*/
let willUpdateScrollTimeout;

/** @type import("gatsby").GatsbyBrowser */
module.exports = {
  shouldUpdateScroll: ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    clearTimeout(willUpdateScrollTimeout);
    const currentPosition = getSavedScrollPosition(location);
    willUpdateScrollTimeout = window.setTimeout(() => {
      if (currentPosition != null) {
        window.scrollTo({ left: currentPosition[0], top: currentPosition[1] });
        return;
      } else {
        const mainContainer = document.getElementById("mainContent");
        if (mainContainer == null) {
          window.scrollTo(0, 0);
          return;
        }
        if (mainContainer.getBoundingClientRect().top >= 0) {
          return;
        } else {
          "scrollIntoView" in mainContainer
            ? mainContainer.scrollIntoView({ block: "start" })
            : window.scrollTo(0, 0);
        }
      }
    }, 125);
    return false;
  },
};
