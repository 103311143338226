import React, { ComponentProps, useState } from "react";
import { colorGoldStroke, colorBlue } from "../../stylingConstants";

const standardFill = "#6F7280";

type BaseIconProps = {
  height?: number;
  width?: number;
  fill?: string;
};

const useRandomString = () => {
  const [random] = useState(Math.random().toString(36));
  return random;
};

export const FacebookLogo = ({
  fill = standardFill,
  height = 32,
  width = 32,
}: BaseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0C7.19999 0 0 7.21285 0 16.0964C0 24.1285 5.85599 30.7952 13.504 32V20.755H9.43999V16.0964H13.504V12.5462C13.504 8.51406 15.888 6.29719 19.552 6.29719C21.296 6.29719 23.12 6.60241 23.12 6.60241V10.5703H21.104C19.12 10.5703 18.496 11.8072 18.496 13.0763V16.0964H22.944L22.224 20.755H18.496V32C22.2663 31.4022 25.6995 29.4707 28.1758 26.5543C30.6522 23.6379 32.0085 19.9287 32 16.0964C32 7.21285 24.8 0 16 0Z"
      fill={fill}
    />
  </svg>
);

export const TwitterLogo = ({
  fill = standardFill,
  height = 32,
  width = 32,
}) => {
  const maskId = useRandomString();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={maskId} fill="white">
          <rect width="32" height="32" fill="white" />
          <path
            d="M24 10.9946C23.4379 11.2642 22.8319 11.4413 22.2041 11.526C22.8465 11.1178 23.343 10.471 23.5766 9.69325C22.9706 10.0783 22.299 10.3478 21.5908 10.5018C21.0141 9.83956 20.2037 9.45453 19.2839 9.45453C17.5682 9.45453 16.1665 10.933 16.1665 12.7581C16.1665 13.0199 16.1957 13.274 16.2468 13.5127C13.6478 13.3741 11.3336 12.0573 9.79315 10.0629C9.52303 10.548 9.36972 11.1178 9.36972 11.7185C9.36972 12.8659 9.91726 13.8823 10.7641 14.4599C10.2458 14.4599 9.76395 14.3059 9.34052 14.0748V14.098C9.34052 15.6997 10.421 17.0396 11.8519 17.3399C11.3925 17.4725 10.9102 17.4909 10.4429 17.3938C10.6412 18.0502 11.0295 18.6246 11.5533 19.0362C12.0771 19.4479 12.71 19.676 13.3631 19.6885C12.2561 20.6129 10.8838 21.1126 9.47193 21.1054C9.22371 21.1054 8.97549 21.09 8.72727 21.0592C10.1144 21.9987 11.7643 22.5454 13.531 22.5454C19.2839 22.5454 22.445 17.5093 22.445 13.1431C22.445 12.9968 22.445 12.8582 22.4377 12.7119C23.0509 12.2498 23.5766 11.6646 24 10.9946Z"
            fill="black"
          />
        </mask>
      </defs>
      <circle cx="16" cy="16" r="16" fill={fill} mask={`url(#${maskId})`} />
    </svg>
  );
};

export const InstagramLogo = ({
  fill = standardFill,
  height = 32,
  width = 32,
}) => {
  const maskId = useRandomString();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={maskId} fill="white">
          <rect width="32" height="32" fill="white" />
          <path
            d="M12.64 8H19.36C21.92 8 24 10.08 24 12.64V19.36C24 20.5906 23.5111 21.7708 22.641 22.641C21.7708 23.5111 20.5906 24 19.36 24H12.64C10.08 24 8 21.92 8 19.36V12.64C8 11.4094 8.48886 10.2292 9.35902 9.35902C10.2292 8.48886 11.4094 8 12.64 8ZM12.48 9.6C11.7162 9.6 10.9836 9.90343 10.4435 10.4435C9.90343 10.9836 9.6 11.7162 9.6 12.48V19.52C9.6 21.112 10.888 22.4 12.48 22.4H19.52C20.2838 22.4 21.0164 22.0966 21.5565 21.5565C22.0966 21.0164 22.4 20.2838 22.4 19.52V12.48C22.4 10.888 21.112 9.6 19.52 9.6H12.48ZM20.2 10.8C20.4652 10.8 20.7196 10.9054 20.9071 11.0929C21.0946 11.2804 21.2 11.5348 21.2 11.8C21.2 12.0652 21.0946 12.3196 20.9071 12.5071C20.7196 12.6946 20.4652 12.8 20.2 12.8C19.9348 12.8 19.6804 12.6946 19.4929 12.5071C19.3054 12.3196 19.2 12.0652 19.2 11.8C19.2 11.5348 19.3054 11.2804 19.4929 11.0929C19.6804 10.9054 19.9348 10.8 20.2 10.8ZM16 12C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20C14.9391 20 13.9217 19.5786 13.1716 18.8284C12.4214 18.0783 12 17.0609 12 16C12 14.9391 12.4214 13.9217 13.1716 13.1716C13.9217 12.4214 14.9391 12 16 12ZM16 13.6C15.3635 13.6 14.753 13.8529 14.3029 14.3029C13.8529 14.753 13.6 15.3635 13.6 16C13.6 16.6365 13.8529 17.247 14.3029 17.6971C14.753 18.1471 15.3635 18.4 16 18.4C16.6365 18.4 17.247 18.1471 17.6971 17.6971C18.1471 17.247 18.4 16.6365 18.4 16C18.4 15.3635 18.1471 14.753 17.6971 14.3029C17.247 13.8529 16.6365 13.6 16 13.6Z"
            fill="black"
          />
        </mask>
      </defs>
      <circle cx="16" cy="16" r="16" fill={fill} mask={`url(#${maskId})`} />
    </svg>
  );
};

export const MyNewsDeskLogo = ({
  fill = standardFill,
  height = 32,
  width = 32,
}) => {
  const maskId = useRandomString();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id={maskId}>
          <rect width="32" height="32" fill="white" />
          <path
            d="M10.1818 8.44408H13.4054V10.196C13.5634 9.92779 13.8852 9.45412 14.4423 9.03791C15.2421 8.38659 16.466 8 17.5045 8C19.0228 8 20.329 8.77147 20.995 9.77891C21.8182 11.0241 21.8182 12.6262 21.8182 14.5558V23.2719H18.6503V15.7113C18.6503 14.6132 18.6503 13.8723 18.5439 13.4569C18.2512 12.1534 17.3989 11.469 16.3604 11.469C15.3219 11.469 14.3633 12.1534 13.9101 13.1321C13.4054 14.2266 13.4054 15.7391 13.4054 16.8362V23.2727H10.1818L10.1818 8.44408Z"
            fill="black"
          />
        </mask>
      </defs>
      <circle cx="16" cy="16" r="16" fill={fill} mask={`url(#${maskId})`} />
    </svg>
  );
};

export const ExternalLink = ({
  fill = standardFill,
  height = 11,
  width = 12,
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0V11H12V8.25H10.5V9.625H1.5V1.375H3V0H0ZM6 0L8.25 2.0625L4.5 5.5L6 6.875L9.75 3.4375L12 5.5V0H6Z"
      fill={fill}
    />
  </svg>
);

export const CrossmarkIcon = ({ fill = "#1B1B1B", ...props }: ComponentProps<"svg">) => (
  <svg
    {...props}
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="13.9798"
      height="1.47155"
      transform="matrix(0.705787 0.708424 -0.705787 0.708424 1.13281 0)"
      fill={fill}
    />
    <rect
      width="13.9798"
      height="1.47155"
      transform="matrix(-0.705787 0.708424 -0.705787 -0.708424 10.9062 1.0979)"
      fill={fill}
    />
  </svg>
);

export const accordionIconMaker = (type: "expand" | "minimize") => ({
  fill = "#8B7C58",
  ...props
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
      fill={fill}
    />
    <rect x="6" y="9" width="8" height="2" fill="white" />
    {type === "expand" && (
      <rect
        x="11"
        y="6"
        width="8"
        height="2"
        transform="rotate(90 11 6)"
        fill="white"
      />
    )}
  </svg>
);

export const PlayIcon = ({ height = 20, width = 20, ...props }) => (
  <svg width={width} height={height} viewBox="0 0 100 100" {...props}>
    <circle
      cx="50"
      cy="50"
      r="48"
      stroke={colorGoldStroke}
      strokeWidth="1"
      fill={colorBlue}
      opacity="0.85"
    />
    <path d="M78 50, 35.25 25, 35.25 75, 78 50" fill={colorGoldStroke} />
  </svg>
);

export const SearchIcon = (props: ComponentProps<"svg">) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.94286 0C7.519 0 9.03059 0.62612 10.1451 1.74062C11.2596 2.85512 11.8857 4.36671 11.8857 5.94286C11.8857 7.41486 11.3463 8.768 10.4594 9.81029L10.7063 10.0571H11.4286L16 14.6286L14.6286 16L10.0571 11.4286V10.7063L9.81029 10.4594C8.73193 11.3799 7.36065 11.8856 5.94286 11.8857C4.36671 11.8857 2.85512 11.2596 1.74062 10.1451C0.62612 9.03059 0 7.519 0 5.94286C0 4.36671 0.62612 2.85512 1.74062 1.74062C2.85512 0.62612 4.36671 0 5.94286 0ZM5.94286 1.82857C3.65714 1.82857 1.82857 3.65714 1.82857 5.94286C1.82857 8.22857 3.65714 10.0571 5.94286 10.0571C8.22857 10.0571 10.0571 8.22857 10.0571 5.94286C10.0571 3.65714 8.22857 1.82857 5.94286 1.82857Z"
      css={{ fill: "#1B1B1B" }}
    />
  </svg>
);

export const Expand = accordionIconMaker("expand");
export const Minimize = accordionIconMaker("minimize");
