import { useState, useEffect } from "react";
import React from "react";
import { useGlobalSiteData } from "../../hooks";
import { css } from "@emotion/core";
import { captionTypeface } from "./Text";
import { CrossmarkIcon } from "./Icons";
import { colorDark } from "../../stylingConstants";
import ContentPageRichText from "../Organisms/ContentPageRichText";

export default function CookieBanner() {
  const { cookieDisclaimer } = useGlobalSiteData();
  const [acceptCookies, setAcceptCookies] = useState<boolean>();

  useEffect(() => {
    switch (acceptCookies) {
      case null: {
        setAcceptCookies(localStorage?.["acceptCookies"] === true);
        return;
      }
      default: {
        localStorage && (localStorage["acceptCookies"] = acceptCookies);
        return;
      }
    }
  }, [acceptCookies]);

  return cookieDisclaimer != null && acceptCookies === false ? (
    <div
      css={css`
        font-family: ${captionTypeface};
        background: white;
        position: relative;
        padding: 12px;
        cursor: pointer;
        transition: background 250ms;
        :hover{
            background: #DDD;
        }
      `}
      onClick={() => setAcceptCookies(true)}
    >
      <CrossmarkIcon
        css={css`
          position: absolute;
          top: 0px;
          right: 0px;
          padding: 12px;
        `}
        fill={colorDark}
      />
      <ContentPageRichText json={cookieDisclaimer} />
    </div>
  ) : null;
}
