import styled from "@emotion/styled";
import { bpSmall, bpMedium, bpLarge } from "../../stylingConstants";

export const displayTypeface = `Freight Disp, Baskerville, Georgia, serif`;
export const textTypeface = `freight-text-pro, Georgia, serif`;
export const captionTypeface = `Founders Grotesk, “Helvetica Neue”, Helvetica, sans-serif`;

export const Heading1 = styled.h1`
  margin: 0;
  font-family: ${displayTypeface};
  font-weight: 400;
  font-size: 33px;
  line-height: 36px;
  @media ${bpSmall} {
    font-size: 38px;
    line-height: 40px;
  }
  @media ${bpMedium} {
    font-size: 42px;
    line-height: 44px;
  }
  @media ${bpLarge} {
    font-size: 60px;
    line-height: 60px;
  }
`;

export const Heading2 = styled.h2`
  margin: 0;
  font-family: ${displayTypeface};
  font-weight: 400;
  font-size: 33px;
  line-height: 36px;
  @media ${bpMedium} {
    font-size: 24px;
    line-height: 24px;
  }
  @media ${bpLarge} {
    font-size: 28px;
    line-height: 28px;
  }
`;

export const Heading3 = styled.h3`
  margin: 0;
  font-family: ${displayTypeface};
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
  @media ${bpLarge} {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Heading4 = styled.h4`
  margin: 0;
  font-family: ${displayTypeface};
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  @media ${bpLarge} {
    font-size: 36px;
    line-height: 36px;
  }
`;

export const Heading5 = styled.h5`
  margin: 0;
  font-family: ${captionTypeface};
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media ${bpLarge} {
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 1.3px;
  }
`;

export const Intro = styled.p`
  font-family: ${displayTypeface};
  font-size: 21px;
  line-height: 28px;
  margin-top: 32px;
  margin-bottom: 32px;
  @media ${bpSmall} {
    font-size: 24px;
    line-height: 32px;
  }
  @media ${bpMedium} {
    margin-top: 44px;
  }
  @media ${bpLarge} {
    margin-top: 48px;
    font-size: 26px;
    line-height: 36px;
  }
`;

export const Caption = styled.caption`
  font-family: ${captionTypeface};
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  @media ${bpLarge} {
    font-size: 16px;
    line-height: 16px;
  }
`;

export const Small = styled.small`
  font-family: ${captionTypeface};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.6%;
  @media ${bpLarge} {
    line-height: 20px;
  }
`;

export const FooterText = styled.li`
  font-family: ${displayTypeface};
  font-size: 24px;
  line-height: 48px;
  @media ${bpLarge} {
    font-size: 36px;
    line-height: 56px;
  }
`;
