const yearBasePath = "/arkiv";

module.exports = {
  /** @param {{slug?: string | null, id: string}} work */
  getWorkRoute(work) {
    return `/bidrag/${work.slug || work.id}`;
  },

  yearBasePath,
  /** @param {number} year */
  getYearRoute(year) {
    return `${yearBasePath}/${year}`;
  },

  /** @param {string} path */
  getCurrentYearFromPath(path) {
    if (!path || path.indexOf(yearBasePath) !== 0) {
      return null;
    }
    const trimmedBasePath = path.substr(yearBasePath.length);
    return trimmedBasePath.substr(0, trimmedBasePath.indexOf("/") || undefined);
  },

  /** @param {String} slug
   */
  getPersonRoute(slug) {
    return `/person/${slug}`;
  },

  /** @param {{slug?: string | null, id: string}} person
   * @param {String} year
   */
  getPersonInYearRoute(person, year) {
    return `/${year}/${person.slug || person.id}`;
  },
};
