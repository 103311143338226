import { Global, css } from "@emotion/core";
import {
  bpSmall,
  bpMedium,
  bpLarge,
  colorDark,
  colorBlue,
} from "./stylingConstants";
import React from "react";

const globalStyle = css`
  html {
    background: ${colorBlue};
  }
  body {
    font-family: "Freight Text";
    background: white;
    color: ${colorDark};
    font-size: 17px;
    line-height: 24px;
    @media ${bpSmall} {
      font-size: 19px;
      line-height: 28px;
    }
    @media ${bpMedium} {
      font-size: 17px;
      line-height: 24px;
    }
    @media ${bpLarge} {
      font-size: 18px;
      line-height: 28px;
    }
  }
  p {
    margin: 12px 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    :hover {
      cursor: pointer;
    }
  }
`;

export default function GlobalStyle() {
  return <Global styles={globalStyle} />;
}
