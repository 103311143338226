import styled from "@emotion/styled";
import {
  horizontalMargin,
  verticalPadding,
  verticalMargin,
} from "../../margins";
import {
  colorGoldStroke,
  colorGoldFill,
  bpLarge,
} from "../../stylingConstants";
import { displayTypeface } from "./Text";

export default styled.blockquote`
  font-family: ${displayTypeface};
  ${horizontalMargin(0)}
  ${verticalMargin(12)}
  padding: 12px 22px;
  border-left: 4px solid ${colorGoldStroke};
  background: ${colorGoldFill};
  font-family: ${displayTypeface};
  font-size: 19px;
  line-height: 26px;
  @media ${bpLarge} {
    font-size: 21px;
    line-height: 28px;
  }
  p {
    font-family: ${displayTypeface};
    font-size: 20px;
    line-height: 32px;
  }
`;
