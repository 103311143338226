import { css } from "@emotion/core";
import React, {
  CSSProperties,
  ReactNode,
  useMemo,
  useEffect,
  useState,
} from "react";
import { bpMedium, bpLarge } from "../../stylingConstants";
import MainContent from "./Main";
import PersonSearchBar from "./PersonSearchBar";

type Props = {
  mainContentStyle?: CSSProperties;
  children: ReactNode;
  leftColumn?: ReactNode;
};

export const ArchiveLayout = (props: Props) => {
  //Effect to fade in on mount
  const [opacity, setOpacity] = useState(0);
  useEffect(() => {
    setOpacity(1);
  });
  const transitionStyle = useMemo(
    () => ({
      opacity,
      transition: "opacity 300ms 300ms",
    }),
    [opacity]
  );
  return (
    <PersonSearchBar css={transitionStyle}>
      <MainContent id="mainContent">
        <div
          css={css`
            display: flex;
            flex-direction: row;
          `}
        >
          <div
            css={[
              css`
                display: none;
                flex-shrink: 0;
                width: 150px;
                @media ${bpMedium} {
                  display: block;
                }
                @media ${bpLarge} {
                  width: 200px;
                }
              `,
              transitionStyle,
            ]}
          >
            {props.leftColumn}
          </div>
          <div
            style={props.mainContentStyle}
            css={css`
              flex: 1;
            `}
          >
            {props.children}
          </div>
        </div>
      </MainContent>
    </PersonSearchBar>
  );
};
