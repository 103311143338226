import styled from "@emotion/styled";
import { colorGoldStroke } from "../../stylingConstants";

export default styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const BulletListItem = styled.li`
  display: block;
  :before {
    content: "";
    font-size: 0px;
    padding: 4px;
    border-radius: 50%;
    background-color: ${colorGoldStroke};
    margin-right: 16px;
    display: inline-block;
    margin-bottom: 1.5px;
  }
`;
