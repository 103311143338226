import {
  EffectCallback,
  useRef,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Document as ContentfulJson } from "@contentful/rich-text-types";
import { GlobalSiteDataQuery, NavItemFragment } from "../graphql-schema-types";

export const useEffectWithDeps = <TDeps extends any[]>(
  fn: (prevDeps?: TDeps) => ReturnType<EffectCallback>,
  deps: TDeps
) => {
  const prevDeps = useRef<TDeps>();
  useEffect(() => {
    const effectResult = fn(prevDeps.current);
    prevDeps.current = deps;
    return effectResult;
  }, deps);
};

const mapMenuItems = (items?: (NavItemFragment | null | undefined)[] | null) =>
  (items?.map(mapMenuItem).filter((item) => item != null) as NonNullable<
    ReturnType<typeof mapMenuItem>
  >[]) ?? [];

const mapMenuItem = (item?: NavItemFragment | null) => {
  if (item == null) {
    return;
  }
  const url =
    item.rawUrl ?? (item.internalLink?.slug && `/${item.internalLink.slug}`);
  if (!url) {
    return;
  }
  const text = item.text ?? item.internalLink?.title;
  return { text, url };
};

const useGlobalGraphQl = (): GlobalSiteDataQuery =>
  useStaticQuery(graphql`
    query GlobalSiteData {
      allContentfulYear {
        distinct(field: year)
      }
      contentfulSharedContent {
        menuItems {
          ...NavItem
        }
        desktopMenuItems {
          ...NavItem
        }
        nominationsOpen
        nominationsClose
        facebookUrl
        instagramUrl
        mynewsdeskUrl
        twitterUrl
        privacyPolicy {
          ... on ContentfulArticlePage {
            slug
          }
        }
        headerCountdown
        headerCountdownText
        headerText
        headerLink {
          ...NavItem
        }
        headerEmbed
        headerEmbedImage {
          fluid(maxWidth: 730, quality: 95) {
            src
          }
        }
        cookieDisclaimer {
          json
        }
      }
    }
  `);

export const useGlobalSiteData = () => {
  const { contentfulSharedContent } = useGlobalGraphQl();
  return useMemo(
    () => ({
      menuItems: mapMenuItems(contentfulSharedContent?.menuItems),
      desktopMenuItems: mapMenuItems(contentfulSharedContent?.desktopMenuItems),
      nominationSchedule: {
        open: new Date(contentfulSharedContent?.nominationsOpen),
        close: new Date(contentfulSharedContent?.nominationsClose),
      },
      socialUrls: {
        facebook: contentfulSharedContent?.facebookUrl,
        instagram: contentfulSharedContent?.instagramUrl,
        mynewsdesk: contentfulSharedContent?.mynewsdeskUrl,
        twitter: contentfulSharedContent?.twitterUrl,
      },
      privacyPolicyUrl: contentfulSharedContent?.privacyPolicy?.slug,
      cookieDisclaimer: contentfulSharedContent?.cookieDisclaimer
        ?.json as ContentfulJson | null,
    }),
    [contentfulSharedContent]
  );
};

/**
 * Since this site is statically generated date compares needs to happen after render
 */
export const useNominationOpenState = () => {
  const [openState, setOpenState] = useState(true);
  const {
    nominationSchedule: { close, open },
  } = useGlobalSiteData();
  useEffect(() => {
    setOpenState(new Date() >= open && new Date() < close);
  }, [open, close]);
  return openState;
};

/**
 * Since this site is statically generated date compares needs to happen after render
 */
export const useSiteHeaderState = () => {
  const [timeLeft, setTimeLeft] = useState<number>();
  const { contentfulSharedContent } = useGlobalGraphQl();
  useEffect(() => {
    const pollTime = () => {
      const calculatedTimeLeft =
        contentfulSharedContent?.headerCountdown != null &&
        new Date(contentfulSharedContent.headerCountdown).getTime() -
          new Date().getTime();

      setTimeLeft(calculatedTimeLeft || 0);
      return calculatedTimeLeft
        ? setTimeout(
            pollTime,
            //It's probably very possible to trigger this the moment hour/minute switches, but i got places to be.
            calculatedTimeLeft > 86400000 ? 9000000 : 20000
          )
        : null;
    };
    const poller = pollTime();
    return () => {
      poller && window.clearTimeout(poller);
    };
  }, [contentfulSharedContent?.headerCountdown]);
  return useMemo(
    () =>
      timeLeft == null
        ? { loaded: false }
        : timeLeft <= 0
        ? ({
            loaded: true,
            open: true,
            text: contentfulSharedContent?.headerText,
            link: mapMenuItem(contentfulSharedContent?.headerLink),
            embed: contentfulSharedContent?.headerEmbed
              ? {
                  src: contentfulSharedContent?.headerEmbed,
                  thumb: contentfulSharedContent?.headerEmbedImage?.fluid?.src,
                }
              : null,
          } as const)
        : ({
            loaded: true,
            open: false,
            text: contentfulSharedContent?.headerCountdownText,
            timeLeft,
            embed: contentfulSharedContent?.headerEmbed
              ? {
                  src: contentfulSharedContent?.headerEmbed,
                  thumb: contentfulSharedContent?.headerEmbedImage?.fluid?.src,
                }
              : null,
          } as const),
    [timeLeft, contentfulSharedContent]
  );
};

const yearMatch = /^\d\d(\d)\d$/;
export const useAwardDecades = () => {
  const {
    allContentfulYear: { distinct: years },
  } = useGlobalGraphQl();
  return useMemo(() => {
    const decadeMap = years
      .sort()
      .reverse()
      .reduce((acc, numberYear) => {
        const stringYear = numberYear.toString();
        const decade = yearMatch.exec(stringYear)?.[1];
        if (decade) {
          acc.set(decade, [...(acc.get(decade) ?? []), stringYear]);
        }
        return acc;
      }, new Map<string, string[]>());
    return Array.from(decadeMap.entries());
  }, [years]);
};

/** Use to keep things around a while for animations and the like */
export const useDelayedFollower = <T>(
  value: T,
  defaultDelay: number,
  ...delays: [T, number][]
) => {
  const [lazyValue, setLazyValue] = useState(value);
  useEffect(() => {
    const timer = setTimeout(
      () => setLazyValue(value),
      delays.find(([val]) => val === value)?.[1] ?? defaultDelay
    );
    return () => clearTimeout(timer);
  }, [value]);
  return lazyValue;
};

export const useRssFeedItems = (feedUrl: string, feedCharset = "iso-8859-1") => {
  const [output, setOutput] = useState<
    { title?: string; published?: Date; href?: string }[]
  >();
  useEffect(() => {
    fetch(`https://cors-anywhere.herokuapp.com/${feedUrl}`)
      .then((response) => response.arrayBuffer())
      .then((buffer) => {
        let decoder = new TextDecoder(feedCharset);
        const text = decoder.decode(buffer);
        const dom = new DOMParser().parseFromString(text, "text/xml");
        setOutput(
          Array.from(dom.childNodes[0].childNodes[1].childNodes)
            .filter((node) => node.nodeName === "item")
            .map((itemNode) => {
              const children = Array.from(itemNode.childNodes);
              const pubDate = children.find(
                (node) => node.nodeName === "pubDate"
              )?.textContent;
              return {
                title:
                  children.find((node) => node.nodeName === "title")
                    ?.textContent ?? undefined,
                published: pubDate != null ? new Date(pubDate) : undefined,
                href:
                  children.find((node) => node.nodeName === "link")
                    ?.textContent ?? undefined,
              };
            })
        );
      });
  }, [feedUrl]);
  return output;
};
