import React, { PropsWithChildren, ComponentProps } from "react";
import { Heading3, Heading1 } from "./Text";
import { css } from "@emotion/core";
import { bpLarge, bpMedium } from "../../stylingConstants";

export function AwardCategoryHeading({ children }: { children: string }) {
  return (
    <Heading3
      css={css`
        margin-top: 28px;
        margin-bottom: 16px;
        @media ${bpLarge} {
          margin-top: 48px;
          margin-bottom: 20px;
        }
      `}
    >
      {children}
    </Heading3>
  );
}

const MainCat = Heading1.withComponent("h2");
export function MainCategoryHeading({
  ...props
}: ComponentProps<typeof MainCat>) {
  return (
    <MainCat
      {...props}
      css={css`
        margin-top: 68px;
        margin-bottom: 22px;
        @media ${bpLarge} {
          margin-top: 110px;
          margin-bottom: 48px;
        }
      `}
    />
  );
}
