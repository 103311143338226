import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  ChangeEvent,
  PropsWithChildren,
  createContext,
  useRef,
  ComponentProps,
  KeyboardEvent,
} from "react";
import React from "react";
import { css } from "@emotion/core";
import { colorGrayFill, bpMedium, bpLarge } from "../../stylingConstants";
import { verticalPadding, horizontalMargin, horizontalPadding } from "../../margins";
import { useDelayedFollower } from "../../hooks";
import styled from "@emotion/styled";
import { Link, navigateTo } from "gatsby";
import { SearchIcon, CrossmarkIcon } from "../Atoms/Icons";

export const PersonSearchContext = createContext({
  active: false,
  activate() {},
});

export default function PersonSearchBar({
  children,
  ...props
}: ComponentProps<"div">) {
  const [peopleWithSlugs, setPeopleWithSlugs] = useState<{
    [name: string]: string;
  }>({});
  useEffect(() => {
    fetch("/persons.json")
      .then((res) => (res.ok ? res.json() : {}))
      .then((people: { [name: string]: string }) => setPeopleWithSlugs(people));
  }, []);

  const people = useMemo(() => Object.keys(peopleWithSlugs), [peopleWithSlugs]);

  const lowercasedPeople = useMemo(
    () => people.map((name) => name.toLowerCase()),
    [people]
  );
  const [search, setSearch] = useState("");
  const [matches, setMatches] = useState<[string, string, string][]>();
  useEffect(() => {
    if (!search.length) {
      setMatches(undefined);
      return;
    }
    const throttledSearch = window.setTimeout(() => {
      const lowerCasedSearch = search.toLowerCase();
      const matchingPeople = [] as [number, [string, string, string]][];
      for (let index = 0; index < lowercasedPeople.length; index++) {
        const matchIndex = lowercasedPeople[index].indexOf(lowerCasedSearch);
        if (matchIndex !== -1) {
          const matchingName = people[index];
          matchingPeople.push([
            matchIndex,
            [
              matchingName.substring(0, matchIndex),
              matchingName.substring(matchIndex, matchIndex + search.length),
              matchingName.substring(matchIndex + search.length),
            ],
          ]);
          if (matchingPeople.length >= 7) {
            break;
          }
        }
      }
      setMatches(
        matchingPeople
          .sort(([indexA], [indexB]) => indexA - indexB)
          .map(([_index, match]) => match)
      );
    }, 100);
    return () => {
      window.clearTimeout(throttledSearch);
    };
  }, [search]);
  const input = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const activate = useCallback(() => {
    input.current && input.current.focus();
  }, [input.current]);
  const activeTransitioning = useDelayedFollower(active, 0, [false, 300]);
  const handleSearchInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    [setSearch]
  );
  const contextValue = useMemo(
    () => ({
      active,
      activate,
    }),
    [active, activate]
  );
  const [focusedIndex, setFocusedIndex] = useState<number>();
  const handleKeyEvents = useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Escape") {
        ev.preventDefault();
        input.current?.blur();
      }

      const indexChange =
        ev.key === "ArrowDown" ? 1 : ev.key === "ArrowUp" ? -1 : null;
      if (indexChange != null) {
        ev.preventDefault();
        setFocusedIndex((index) => {
          if (index == null || matches == null) {
            return 0;
          }
          const newIndex = index + indexChange;
          if (newIndex >= matches.length) {
            return 0;
          }
          if (newIndex < 0) {
            return matches.length - 1;
          }
          return newIndex;
        });
      }
      if (ev.key === "Enter" && focusedIndex != null && matches != null) {
        ev.preventDefault();
        navigateTo(
          `/person/${peopleWithSlugs[matches[focusedIndex].join("")]}`
        );
        input.current?.blur();
      }
    },
    [matches, focusedIndex]
  );
  return (
    <PersonSearchContext.Provider value={contextValue}>
      <div
        css={[
          css`
            top: 0px;
            background: white;
            text-align: center;
            position: fixed;
            width: 100%;
            z-index: 2;
            transform: translateY(-100%);
            transition: transform 700ms;
            transition-timing-function: ease-out;
            @media ${bpMedium} {
              transform: none;
              position: static;
              display: block;
              width: auto;
              z-index: unset;
              background: ${colorGrayFill};
            }
          `,
          active &&
            css`
              transform: translateY(0%);
              transition-timing-function: ease-in;
            `,
        ]}
        {...props}
      >
        <div
          css={css`
            display: flex;
            cursor: pointer;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            ${horizontalPadding(24)}
            ${verticalPadding(12)}
            @media ${bpLarge}{
              ${verticalPadding(18)}
            }
          `}
          onMouseDown={active ? activate : undefined}
        >
          <SearchIcon
            css={css`
              margin-top: -2.2px;
              width: 12px;
              height: 12px;
              @media ${bpLarge} {
                width: 14px;
                height: 14px;
              }
            `}
          />
          <input
            ref={input}
            value={active ? search : ""}
            onChange={handleSearchInput}
            placeholder="Sök efter person"
            onFocus={() => {
              setActive(true);
            }}
            onBlur={() => setActive(false)}
            onClick={(ev) => active && ev.stopPropagation()}
            onKeyDown={handleKeyEvents}
            css={[
              css`
                /**Im sorry, i have sinned. */
                outline: none;
                background: transparent;
                border: none;
                width: 120px;
                padding: 10px 0;
                transition: width 200ms;
                ${horizontalMargin(8)}
                @media ${bpLarge} {
                  ${horizontalMargin(12)}
                  font-size: 19;
                }
                ::placeholder{
                  color: transparent;
                  @media ${bpMedium}{
                    color: #828282;
                  }
                }
              `,
              active &&
                css`
                  width: 500px;
                  max-width: 90%;
                `,
            ]}
          />
          <CrossmarkIcon
            css={css`
              ${
                !active &&
                css`
                  width: 0 !important;
                  height: 0 !important;
                `
              }
              opacity: ${active ? 1 : 0};
              margin-top: -2.2px;
              width: 9px;
              height: 9px;
              transition: opacity ${active ? 300 : 50}ms;
              @media ${bpLarge} {
                width: 11px;
                height: 11px;
              }
            `}
            onClick={(ev) => (ev.preventDefault(), input.current?.blur())}
          >
            Stäng
          </CrossmarkIcon>
        </div>
      </div>
      <div
        css={[
          css`
            position: relative;
            box-sizing: border-box;
            padding: 0.1px;
          `,
        ]}
      >
        {(active || activeTransitioning) && (
          <>
            <div
              onClick={input.current?.blur}
              css={css`
                top: 0;
                width: 100%;
                height: 100%;
                background: ${active && activeTransitioning
                  ? "rgba(0,0,0,0.3)"
                  : "transparent"};
                z-index: 1;
                transition: background 250ms;
                position: fixed;
                @media ${bpMedium} {
                  position: absolute;
                }
              `}
            >
              <ul
                css={css`
                  width: 100%;
                  max-width: 100%;
                  margin: 0 auto;
                  padding: 12px 0;
                  opacity: ${active && activeTransitioning ? 1 : 0};
                  background: white;
                  z-index: 2;
                  position: relative;
                  box-sizing: border-box;
                  top: 60px;
                  @media ${bpMedium} {
                    top: 0;
                    width: 570px;
                  }
                `}
              >
                {search.length && matches ? (
                  matches.length ? (
                    matches.map((match, index) => {
                      const key = match.join("");
                      return (
                        <SearchItem key={key} noPad>
                          <SearchLink
                            to={`/person/${peopleWithSlugs[key]}`}
                            onMouseEnter={() => setFocusedIndex(index)}
                            css={
                              index === focusedIndex && {
                                background: "#F3F3F4",
                              }
                            }
                          >
                            {match[0]}
                            <strong>{match[1]}</strong>
                            {match[2]}
                          </SearchLink>
                        </SearchItem>
                      );
                    })
                  ) : (
                    <SearchItem>Inga träffar för "{search}"</SearchItem>
                  )
                ) : (
                  <SearchItem>Skriv namn på person för att söka.</SearchItem>
                )}
              </ul>
            </div>
          </>
        )}
        {children}
      </div>
    </PersonSearchContext.Provider>
  );
}

const SearchItem = styled.li<{ noPad?: true }>`
  list-style: none;
  display: block;
  transition: background 250ms;
  max-width: 570px;
  margin: 0 auto;
  ${({ noPad }) =>
    !noPad &&
    css`
      padding: 12px 28px;
    `}
`;

const SearchLink = SearchItem.withComponent(Link);
