import React, { ComponentProps, useState, useCallback } from "react";
import { PlayIcon } from "./Icons";
import { css } from "@emotion/core";
import { horizontalMargin } from "../../margins";
import { bpLarge } from "../../stylingConstants";

const embeddedTypes: [string, RegExp][] = [
  ["Youtube", /^https:\/\/www\.youtube\.com\/watch\/?\?v=([-\w]*)/],
  ["Youtube", /^https:\/\/www.youtube.com\/embed\/([-\w]*)/],
  ["Youtube", /^https:\/\/youtu.be\/([-\w]*)/],
];

const getEmbedUrlAndThumb = (
  category: typeof embeddedTypes[0][0],
  embedReference: string
) => {
  switch (category) {
    case "Youtube":
      return [
        `https://www.youtube.com/embed/${embedReference}/?modestBranding=true&autoplay=1`,
        `https://img.youtube.com/vi/${embedReference}/maxresdefault.jpg`,
      ] as const;
  }
};

type Props = ComponentProps<"iframe"> & { src: string; thumbSrc?: string };

export default function VideoEmbed({ src, thumbSrc, ...props }: Props) {
  const pattern = embeddedTypes.find(
    (type) => src && type && type[1].test(src)
  );
  const embedReference = pattern?.[1].exec(src)?.[1] ?? src;
  const [url, videoThumb] = (pattern &&
    getEmbedUrlAndThumb(pattern[0], embedReference)) || [embedReference];
  const [activated, setActivated] = useState(false);
  const activate = useCallback(() => setActivated(true), []);
  const thumb = thumbSrc ?? videoThumb;

  return activated || !thumb ? (
    <iframe
      src={url}
      frameBorder={0}
      allowFullScreen
      css={css`
        background-color: black;
      `}
      {...props}
      allow={`${thumb ? "autoplay; " : ""}encrypted-media`}
    />
  ) : (
    <div
      css={css`
        background-image: url(${thumb});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        box-sizing: border-box;
        ${horizontalMargin("auto")}
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
      onClick={activate}
      {...props}
    >
      {/* <div css={css`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(34, 37, 54, 0.3);
        backdrop-filter: blur(0.7px);
      `}> */}
      <PlayIcon
        css={css`
          border-radius: 50%;
          display: block;
          backdrop-filter: blur(2px);
          z-index: 1;
          height: 82px;
          width: 82px;
          @media ${bpLarge} {
            height: 92px;
            width: 92px;
          }
        `}
      />
      {/* </div> */}
    </div>
  );
}
