import { css, CSSObject, SerializedStyles } from "@emotion/core";
import { Link } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { useAwardDecades } from "../../hooks";
import { verticalMargin, verticalPadding } from "../../margins";
import routeHelper from "../../routeHelper";
import { bpLarge, bpMedium, colorGoldStroke } from "../../stylingConstants";
import { Expand, Minimize } from "../Atoms/Icons";
import { captionTypeface } from "../Atoms/Text";
import CategoryOrRolePicker from "./CategoryPicker";
import { Button } from "../Atoms/Button";

type Props = {
  selectedOption?: string;
  menuCss?: SerializedStyles | CSSObject;
};

export default function YearSelectMenu({
  selectedOption,
  menuCss,
}: Props) {
  const decades = [...useAwardDecades()];
  const activeDecade = useMemo(
    () =>
      selectedOption &&
      decades.find(
        ([_decade, years]) => years.indexOf(selectedOption) > -1
      )?.[0],
    [decades, selectedOption]
  );
  const [selectedDecade, selectDecade] = useState(activeDecade);
  useEffect(() => {
    selectDecade(activeDecade);
  }, [activeDecade]);

  return (
      <ul
        css={[
          css`
            margin: 0;
            padding: 0;
            list-style: none;
            @media ${bpMedium} and (min-height: 570px) {
              position: sticky;
              top: 50px;
            }
            @media ${bpLarge} and (min-height: 630px) {
              position: sticky;
              top: 72px;
            }
          `,
          menuCss,
        ]}
      >
        {decades.map(([decade, years]) => {
          const isSelectedDecade = selectedDecade === decade;
          const Icon = isSelectedDecade ? Minimize : Expand;
          return (
            <li
              key={decade}
              css={css`
                ${verticalMargin(8)}
              `}
            >
              <a
                onClick={() =>
                  selectDecade(!isSelectedDecade ? decade : undefined)
                }
                css={css`
                  font-size: 17px;
                  ${verticalPadding(4)}
                  font-weight: 800;
                  @media ${bpLarge} {
                    font-size: 19px;
                  }
                `}
              >
                <Icon
                  css={css`
                    position: relative;
                    top: 6px;
                    @media ${bpLarge} {
                      top: 5px;
                    }
                  `}
                />
                <span css={{ marginLeft: 12 }}>{`${decade}0-talet`}</span>
              </a>
              <ul
                css={css`
                display: block;
                list-style: none;
                border-left: 2px solid ${colorGoldStroke};
                padding-left: 22px;
                margin-left: 9px;
                overflow: hidden;
                transition: height 250ms ease-in-out;
                height: ${isSelectedDecade ? 26 * years.length - 11 : 0}px;
                ${verticalMargin(isSelectedDecade ? 12 : 0)}
                @media ${bpLarge} {
                  height: ${isSelectedDecade ? 32 * years.length - 14 : 0}px;
                  ${verticalMargin(isSelectedDecade ? 16 : 0)}
                }
              `}
              >
                {years.map((year) => (
                  <li
                    key={year}
                    css={css`
                      display: block;
                      opacity: ${isSelectedDecade ? 1 : 0};
                      transition: opacity ${isSelectedDecade ? 150 : 0}ms
                        ease-out;
                      transition-delay: ${isSelectedDecade ? 250 : 0}ms;
                      font-family: ${captionTypeface};
                      font-weight: ${selectedOption === year ? 500 : 400};
                      font-size: 14px;
                      line-height: 14px;
                      ${verticalPadding(6)}
                      :first-of-type {
                        padding-top: 0;
                      }
                      :last-of-type {
                        padding-bottom: 0;
                      }
                      @media ${bpLarge} {
                        font-size: 16px;
                        line-height: 16px;
                        ${verticalPadding(8)}
                      }
                    `}
                  >
                    <Link to={routeHelper.getYearRoute(parseInt(year))}>
                      {year}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
  );
}
